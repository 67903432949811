import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import PropTypes from "prop-types";
import ImageResize from "quill-image-resize";

ReactQuill.Quill.register("modules/imageResize", ImageResize);

const WysiwygText = forwardRef(
  (
    { label, required, send_to_parent_func, val = "", disabled = null },
    ref
  ) => {
    const [value, setValue] = useState("");
    if (val !== "" && value === "") setValue(val);
    const quillRef = useRef(null);
    useEffect(() => {
      const quillEditor = quillRef.current.getEditor();
      quillEditor.root.addEventListener("paste", handlePaste);

      return () => {
        quillEditor.root.removeEventListener("paste", handlePaste);
      };
    }, []);
    const handleWysiwygChange = (content, _, source, editor) => {
      if (content === "<p><br></p>") content = "";
      setValue(content);
      if (send_to_parent_func) send_to_parent_func(content);
    };
    useImperativeHandle(ref, () => {
      return { getValue: () => value, setText: (text) => setValue(text) };
    });

    const handlePaste = (e) => {
      if (
        e.clipboardData &&
        e.clipboardData.items &&
        e.clipboardData.items[0]
      ) {
        var item = e.clipboardData.items[0];
        if (item.kind === "file" && item.type.indexOf("image/") !== -1) {
          e.preventDefault();
          var blob = item.getAsFile();
          var reader = new FileReader();
          reader.onload = (event) => {
            var img = new Image();
            img.onload = () => {
              // Validate width and height
              const maxWidth = 350; // Example max width
              const maxHeight = 350; // Example max height
              let width = img.width;
              let height = img.height;

              if (width > maxWidth) {
                height = (maxWidth / width) * height;
                width = maxWidth;
              }
              if (height > maxHeight) {
                width = (maxHeight / height) * width;
                height = maxHeight;
              }

              const canvas = document.createElement("canvas");
              canvas.width = width;
              canvas.height = height;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0, width, height);

              // Get the resized image data
              const resizedImageData = canvas.toDataURL("image/png");

              if (quillRef.current) {
                let range = quillRef.current.getEditor().getSelection();
                quillRef.current
                  .getEditor()
                  .insertEmbed(range.index, "image", resizedImageData);
              }
            };
            img.src = event.target.result;
          };
          reader.readAsDataURL(blob);
        }
      }
    };

    useEffect(() => {
      const quillEditor = quillRef.current.getEditor();
      quillEditor.root.addEventListener("paste", handlePaste);

      return () => {
        quillEditor.root.removeEventListener("paste", handlePaste);
      };
    }, []);

    return (
      <div className="form-group">
        <label htmlFor={label} className="black">
          <b>{label}</b> {required && <span style={{ color: "red" }}>*</span>}
        </label>
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={value}
          onChange={handleWysiwygChange}
          modules={{ clipboard: { matchVisual: false }, imageResize: {} }}
          onPaste={handlePaste}
          readOnly={disabled}
        />
        {/*error && <span className='text-danger'>{error}</span>*/}
      </div>
    );
  }
);

WysiwygText.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default WysiwygText;
