import React, { useEffect, useState } from "react";
import { ratingSessionCheck } from "../../Service/AgentService";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Rating from "react-rating-stars-component";
import { rateAgentServiceOnRequest } from "../../Service/ServiceRequestService";
import { rateAgentServiceOnIncident } from "../../Service/IncidentService";
import Swal from "sweetalert2";

const TicketRating = () => {
  let { userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { ticket_no, session } = useParams();
  const [ticket_data, setTicketData] = useState({});
  const [rating, setRating] = useState(0);
  const company_id = localStorage.getItem("company_id");

  const handleRatingChange = (newRating, ticket_data) => {
    setRating(newRating);
    if (ticket_data?.ticket_type == "incident") {
      rateAgentServiceOnIncident(ticket_data.ticket_number, session, {
        rating: newRating,
        company_id,
      }).then((res) => {
        if (res.data.status == 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Rating submitted successfully",
          }).then(() => {
            navigate(`/requester-home`);
          });
        }
      });
    } else {
      rateAgentServiceOnRequest(ticket_data.ticket_number, session, {
        rating: newRating,
        company_id,
      }).then((res) => {
        if (res.data.status == 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Rating submitted successfully",
          }).then(() => {
            navigate(`/requester-home`);
          });
        }
      });
    }
  };

  const renderDetailLink = (ticket) => {
    let link_type = "";
    if (ticket?.ticket_type == "service_request") link_type = "service-request";
    else if (ticket?.ticket_type == "incident") link_type = "incident";

    let res = `/${link_type}/${ticket.ticket_id}`;
    return res;
  };

  useEffect(() => {
    ratingSessionCheck(ticket_no, session, (res) => {
      setTicketData(res.data.data);
      if (res.data.status != 200) {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Ticket not found or already rated",
        }).then(() => {
          navigate(`/requester-home`);
        });
      } else if (res.data.data) {
        const { requester_email } = res.data.data;
        if (requester_email != userInfo?.email) {
          navigate(`/requester-home`);
        } else if (res.data.data.agent_rating) {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "Rating already submitted",
          }).then(() => {
            navigate(`/requester-home`);
          });
        }
      }
    });
  }, []);

  return (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="card shadow mb-4">
            <div className="card-header po-card-header">
              <div className="container-fluid">
                <div style={{ display: "flex", columnGap: "20px" }}>
                  <div>
                    <span
                      className="ticket-title fw-500 black"
                      style={{ display: "block", fontWeight: "300" }}
                    >
                      <h3 className="bold" style={{ marginBottom: "0.1rem" }}>
                        Hi, <span>{userInfo.fullname}</span>!
                      </h3>
                      Please give us your rating for our service on this ticket:
                    </span>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      border: "1px dashed black",
                      marginTop: "20px",
                      padding: "10px",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          display: "block",
                          fontWeight: "300",
                          marginTop: "0.5rem",
                          marginLeft: "1rem",
                          fontSize: "0.9rem",
                        }}
                      >
                        Ticket Number:&nbsp;{" "}
                        <span
                          className="black"
                          style={{ fontWeight: "600", marginLeft: "5px" }}
                        >
                          {ticket_data?.ticket_number}
                        </span>
                      </span>
                    </div>
                    <div>
                      <span
                        style={{
                          display: "block",
                          fontWeight: "300",
                          marginTop: "0.5rem",
                          marginLeft: "1rem",
                          fontSize: "0.9rem",
                        }}
                      >
                        Ticket Topic:&nbsp;{" "}
                        <span
                          className="black"
                          style={{ fontWeight: "600", marginLeft: "20px" }}
                        >
                          {ticket_data?.category_name}
                        </span>
                      </span>
                    </div>
                    <div>
                      <span
                        style={{
                          display: "block",
                          fontWeight: "300",
                          marginTop: "0.5rem",
                          marginLeft: "1rem",
                          fontSize: "0.9rem",
                        }}
                      >
                        Ticket Detail:&nbsp;{" "}
                        <span
                          className="black"
                          style={{ fontWeight: "600", marginLeft: "17px" }}
                        >
                          <a
                            target="_blank"
                            style={{
                              color: "#2563EB",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              window.open(
                                renderDetailLink(ticket_data),
                                "_blank",
                                "noopener,noreferrer"
                              );
                            }}
                          >
                            Click here
                          </a>
                        </span>
                      </span>
                    </div>
                    <div>
                      <span
                        style={{
                          display: "block",
                          fontWeight: "300",
                          marginTop: "0.5rem",
                          marginLeft: "1rem",
                          fontSize: "0.9rem",
                        }}
                      >
                        Ticket Agent:&nbsp;{" "}
                        <span
                          className="black"
                          style={{ fontWeight: "600", marginLeft: "17px" }}
                        >
                          {ticket_data?.agent_name}
                        </span>
                      </span>
                    </div>
                    <div>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            display: "block",
                            fontWeight: "300",
                            marginTop: "0.5rem",
                            marginLeft: "1rem",
                            fontSize: "0.9rem",
                          }}
                        >
                          Service Rating:&nbsp;{" "}
                        </div>
                        <div style={{ marginLeft: "8px" }}>
                          <Rating
                            count={5}
                            value={rating}
                            onChange={(newRating) =>
                              handleRatingChange(newRating, ticket_data)
                            }
                            size={24}
                            activeColor="#ffd700"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TicketRating;
