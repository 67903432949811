import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { modena_letter } from "../Images";
import { logout } from "../Redux/Action/AuthAction";
import { useNavigate } from "react-router-dom";
import InitialIcon from "./InitialIcon";
import { includes, isArray } from "lodash";
import { NavLink } from "react-router-dom";
import { updateReadStatus } from "../Service/NotificationService";
import moment from "moment";

const Topbar = () => {
  let { userInfo } = useSelector((state) => state.auth);
  const is_null_url = userInfo?.profpic == "null" || userInfo?.profpic == null;
  const tempImageName =
    localStorage.getItem("image_name") || `${userInfo?.profpic}`;
  const navigate = useNavigate();
  if (!userInfo) {
    userInfo = {};
  }

  const companies = JSON.parse(localStorage.getItem("companies"));
  const company_id = localStorage.getItem("company_id");
  const [notification_open, setNotificationOpen] = useState(false);
  const [profile_open, setProfileOpen] = useState(false);
  const [socket, setSocket] = useState(null);
  const wsRef = useRef(null);
  const pingIntervalRef = useRef(null);
  const [retryCount, setRetryCount] = useState(0);
  const [notification, setNotification] = useState([{}]);
  const [unread_notif_total, setUnreadNotifTotal] = useState(0);
  const BASE_SOCKET_URL = process.env.REACT_APP_BASE_SOCKET;

  const dispatch = useDispatch();

  const signOut = () => {
    dispatch(logout(userInfo?.role_name));
    navigate("/login");
  };

  useEffect(() => {
    if (notification.length > 0) {
      let unread_notif = notification.filter((item) => item.is_read === false);
      setUnreadNotifTotal(unread_notif.length);
    }
  }, [notification]);

  const updateNotif = (e, item) => {
    const notif_id = item._id["$oid"];
    updateReadStatus(notif_id, {
      is_read: true,
    });
  };

  useEffect(() => {
    const connectWebSocket = () => {
      if (
        wsRef.current &&
        (wsRef.current.readyState === WebSocket.OPEN ||
          wsRef.current.readyState === WebSocket.CONNECTING)
      ) {
        return; // If so, do not attempt to connect again
      }
      if (wsRef.current) {
        wsRef.current.close(); // Ensure the previous connection is closed
      }

      const ws = new WebSocket(BASE_SOCKET_URL);
      wsRef.current = ws;

      ws.addEventListener("open", () => {
        ws.send(JSON.stringify({ email: userInfo?.email }));
        setRetryCount(0); // Reset retry count on successful connection

        pingIntervalRef.current = setInterval(() => {
          ws.send(JSON.stringify({ email: userInfo?.email }));
        }, 20000);
      });

      ws.addEventListener("message", (event) => {
        if (event.data && includes(event.data, "Recv")) {
          let datum = JSON.parse(event.data.substring(5, event.data.length));
          if (isArray(datum)) setNotification(datum);
        }
      });

      ws.addEventListener("close", () => {
        const delay = Math.min(1000 * Math.pow(2, retryCount), 30000);
        setTimeout(() => {
          setRetryCount((prevRetryCount) => prevRetryCount + 1);
          connectWebSocket();
        }, delay);
      });
    };

    connectWebSocket();

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
      if (pingIntervalRef.current) {
        clearInterval(pingIntervalRef.current); //prevent memory leak. If not cleared, it might run in the background improperly
      }
    };
  }, [retryCount]);

  return (
    <nav className="navbar navbar-expand bg-white topbar mb-4 sticky-top shadow">
      <button
        id="sidebarToggleTop"
        className="btn btn-link d-md-none rounded-circle"
      >
        <i className="fa fa-bars"></i>
      </button>

      {/* <form
                className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                <div className="input-group">
                    <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..."
                        aria-label="Search" aria-describedby="basic-addon2" />
                    <div className="input-group-append">
                        <button className="btn btn-outline-primary" type="button">
                            <i className="fas fa-search fa-sm"></i>
                        </button>
                    </div>
                </div>
            </form> */}

      <div
        className="row"
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div className="col-md-6">
          <div
            style={{ marginTop: "0.55em", display: "flex", columnGap: "17px" }}
          >
            <div>
              <img src={modena_letter} style={{ width: 30 }} />
            </div>
            <h5 className="black bold">MODENA Servicedesk</h5>
          </div>
          {/*<div class="input-group mt-1">
                        <div class="form-outline" style={{width: "50%", display:"flex", alignItems:"center", backgroundColor:"#ECECEC", borderRadius: "5px"}}>
                            <i className='fa fa-search' style={{marginLeft:"10px"}}/>
                            <input type="search" id="search-univ" class="form-control" placeholder="Search" style={{width: "100%", border:0,backgroundColor:"#ECECEC"}}/>
                        </div>
        </div>*/}
        </div>

        <div className="col-md-6" style={{ display: "flex", columnGap: "2em" }}>
          <div
            style={{ display: "flex", marginLeft: "auto", columnGap: "20px" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              Department:&nbsp;
              <span className="black bold">
                {companies && companies[company_id]}
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                className="rounded-circle"
                style={{
                  backgroundColor: "#ECECEC",
                  padding: "0.4em 0.65em",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setNotificationOpen(!notification_open);
                  if (profile_open) setProfileOpen(!profile_open);
                }}
              >
                <i className="fas fa-bell" style={{ fontSize: "1em" }} />
                {notification_open && (
                  <div
                    className="notification-after"
                    onBlur={() => {
                      setProfileOpen(false);
                      setNotificationOpen(false);
                    }}
                    tabIndex={notification_open ? -1 : 0}
                  >
                    <div style={{ padding: "10px 20px 10px 20px" }}>
                      <span className="black bold" style={{ fontSize: "1rem" }}>
                        Your Notifications
                      </span>
                    </div>
                    <div
                      className="flex"
                      style={{
                        justifyContent: "space-between",
                        padding: "0 20px 0px 20px",
                        borderBottom: "1px solid #D0D5DD",
                      }}
                    ></div>
                    <div>
                      {notification?.map((item, index) => {
                        return (
                          <>
                            <div className="b2b-notification-msg">
                              <div>
                                <NavLink
                                  target={"_blank"}
                                  to={item.link}
                                  onClick={(e) => updateNotif(e, item)}
                                  style={{
                                    marginTop: "5px",
                                    fontSize: "0.8rem",
                                    fontWeight: 600,
                                    color: "rgb(53, 118, 194)",
                                  }}
                                  className="black"
                                >
                                  {item.message}
                                </NavLink>
                                <div
                                  style={{
                                    marginLeft: "auto",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  <span className="black right">
                                    {moment(item.created_date).fromNow()}
                                  </span>
                                </div>
                              </div>
                              {/* this is how you put one flex item to the most right. See https://www.w3.org/TR/css-flexbox-1/#auto-margins */}
                            </div>
                          </>
                        );
                      })}

                      <div
                        className="text-center"
                        style={{
                          marginTop: "10px",
                          padding: "10px 0px",
                          borderTop: "1px solid #D0D5DD",
                        }}
                      >
                        <span
                          style={{ color: "black" }}
                          onClick={(_) => navigate("/notifications-all")}
                        >
                          See More...
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {unread_notif_total > 0 && (
                <div
                  style={{
                    position: "absolute",
                    top: "-8px",
                    right: "60px",
                    backgroundColor: "red",
                    borderRadius: "50%",
                    width: "20px",
                    height: "20px",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "12px",
                  }}
                >
                  {unread_notif_total}
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "auto",
              }}
            >
              {/*<div style={{marginRight: "10px"}}>
                                <span className='black block'>{userInfo?.fullname}</span>
                                <span className='block'>{userInfo?.username}</span>
                            </div>*/}

              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  className="rounded-circle"
                  style={{ backgroundColor: "#ECECEC", cursor: "pointer" }}
                  onClick={() => {
                    setProfileOpen(!profile_open);
                    if (notification_open)
                      setNotificationOpen(!notification_open);
                  }}
                >
                  {(!userInfo?.profpic || is_null_url) && (
                    <InitialIcon
                      name={userInfo.fullname}
                      icon_width={40}
                      icon_height={40}
                      icon_radius={20}
                      text_size={22}
                    ></InitialIcon>
                  )}
                  {userInfo?.profpic && !is_null_url && (
                    <img
                      className="icons-topbar rounded-circle"
                      src={tempImageName}
                    />
                  )}
                  {profile_open && (
                    <div
                      className="profile-after"
                      onBlur={() => {
                        setProfileOpen(false);
                        setNotificationOpen(false);
                      }}
                      tabIndex={profile_open ? -1 : 0}
                    >
                      <div>
                        <div
                          className="b2b-profile-menu"
                          style={{ borderBottom: "1px solid #D0D5DD" }}
                        >
                          <a
                            className="black"
                            style={{ padding: "3px 0 9px 0" }}
                            onClick={() => navigate("/profile")}
                          >
                            Profile
                          </a>
                        </div>
                        <div
                          className="b2b-profile-menu"
                          style={{ borderBottom: "1px solid #D0D5DD" }}
                        >
                          <a
                            className="black"
                            style={{ padding: "3px 0 9px 0" }}
                            onClick={() => navigate("/departments")}
                          >
                            Change Department
                          </a>
                        </div>
                        <div
                          className="b2b-profile-menu"
                          style={{ paddingBottom: "5px" }}
                        >
                          <a
                            className="black"
                            style={{ padding: "3px 0 10px 0" }}
                            onClick={() => signOut()}
                          >
                            Logout
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Topbar;
