import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./Redux/Store/Store";
import axios from "axios";
import { ACTION_LOGIN, ACTION_LOGOUT } from "./Redux/Action/AuthAction";
import { getRoleById } from "./Service/UserService";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const { $ } = window;
const REFRESH_URL = "v1/refresh";
const LOGIN_URL = "v1/login";
const VALIDATION_URL = "v1/validate-user";
const USERTOKEN = {
  set: (access_token, resfresh_token) => {
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("refresh_token", resfresh_token);
  },
  remove: () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  },
  get: () => ({
    access_token: localStorage.getItem("access_token"),
    refresh_token: localStorage.getItem("refresh_token"),
  }),
};

axios.defaults.baseURL = process.env.REACT_APP_URL;
//axios.defaults.baseURL = "https://servicedesk-dev.modena.com/api/"
//axios.defaults.baseURL = "http://localhost:9502/"
let isRefreshing = false;
let failRequests = [];
axios.interceptors.response.use(
  (ok200) => ok200,
  (error) => {
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;
    if (status === 401) {
      if (
        config.url === REFRESH_URL ||
        config.url === LOGIN_URL ||
        config.url === VALIDATION_URL
      ) {
        return Promise.reject(error);
      }

      if (isRefreshing == false) {
        isRefreshing = true;
        const refreshToken = USERTOKEN.get().refresh_token;
        axios
          .post(REFRESH_URL, {
            refreshToken,
            company_id: localStorage.getItem("company_id"),
          })
          .then(async (response) => {
            if (response && response.status == 200) {
              isRefreshing = false;
              let retryCount = Number(localStorage.getItem("retryCount")) || 0;
              if (retryCount >= 3) {
                localStorage.removeItem("retryCount");
                return Promise.reject(error);
              }
              localStorage.setItem("retryCount", retryCount + 1);
              const { access_token, refresh_token } = response.data;
              const [header, payload] = access_token.split(".");
              const userInfo = JSON.parse(atob(payload));
              const profpic_base_url =
                process.env.REACT_APP_IMAGE_URL + "profpic/";
              if (userInfo?.profpic) {
                const tempImageName =
                  localStorage.getItem("image_name") ||
                  `${profpic_base_url}${userInfo?.profpic}`;
                userInfo.profpic = tempImageName;
              } else {
                userInfo.profpic = null;
              }

              axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${access_token}`;
              axios.defaults.headers.common["company_id"] =
                localStorage.getItem("company_id");
              USERTOKEN.set(access_token, refresh_token);

              if (userInfo?.role_id) {
                const response = await getRoleById(
                  userInfo?.role_id,
                  localStorage.getItem("company_id")
                );
                //const { privileges } = response.data;
                //userInfo?.privileges = privileges;
              }
              store.dispatch({ type: ACTION_LOGIN, userInfo });
              failRequests.map((callback) => callback(access_token));
            }
          })
          .catch((errorRefresh) => {
            isRefreshing = false;
            $(".modal-backdrop").remove();
            console.log("get refresh token fail", errorRefresh);
            store.dispatch({ type: ACTION_LOGOUT });
            return Promise.reject(error);
          });
      }

      const retryOrigReq = new Promise((resolve, reject) => {
        failRequests.push((token) => {
          originalRequest.headers["Authorization"] = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });

      if (localStorage.getItem("retryCount") <= 3) {
        let retryCount = Number(localStorage.getItem("retryCount")) || 0;
        localStorage.setItem("retryCount", retryCount + 1);
        return retryOrigReq;
      }
      localStorage.removeItem("retryCount");
    } else {
      return Promise.reject(error);
    }
  }
);

const access_token = localStorage.getItem("access_token");

if (access_token) {
  //get user info from token
  axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
  const [header, payload] = access_token.split(".");
  //get user info from token
  const userInfo = JSON.parse(atob(payload));
  const profpic_base_url = process.env.REACT_APP_IMAGE_URL + "profpic/";
  if (userInfo?.profpic) {
    const tempImageName =
      localStorage.getItem("image_name") ||
      `${profpic_base_url}${userInfo?.profpic}`;
    userInfo.profpic = tempImageName;
  } else {
    userInfo.profpic = null;
  }
  store.dispatch({ type: ACTION_LOGIN, userInfo });
} else {
  store.dispatch({ type: ACTION_LOGOUT });
}

const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <App />
    </Provider>
  </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
