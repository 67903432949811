import logo from "./logo.jpg";
import menu_user from "./people.png";
import bglogin from "./bg_login.png";
import login_bg from "./login_bg.png";
import caret_up from "./caret_up_md.png";
import chrome_tote from "./chrome_tote.png";
import chart_up from "./chart_up.png";
import chart_down from "./chart_down.png";
import id_flag from "./id_flag.png";
import pp_default from "./pp_default.jpg";
import trash from "./trash.png";
import serious_warning from "./serious_warning.png";
import microsoft from "./microsoft.png";
import notification from "./notification.svg";
import approval from "./approval.png";
import data_update from "./system_update_alt.png";
import data_update_dark from "./system_update_dark.png";
import modena from "./modena.png";
import modena_letter from "./modena-letter.png";
import modena_logo from "./logo.png";
import datetime_input from "./datetime-input.png";
import service from "./service.png";
import browse from "./browse.png";
import browse2 from "./browse2.png";
import mailbox from "./mailbox.svg";
import pending from "./pending.png";
import unresolved from "./unresolved.png";
import overdue from "./overdue.png";
import services from "./services.svg";
import incident from "./incident.svg";
import issues from "./issues.svg";
import service_request from "./service-request.svg";
import dashboard from "./dashboard.svg";
import newtickets from "./new_tickets.png";
import requester_illus from "./servicedesk-requester.png";
import bg_login_servicedesk from "./bg_login-servicedesk2.png";
import brain from "./brain.png";
import paint from "./paint.png";
import it from "./it.png";
export {
  bg_login_servicedesk,
  brain,
  paint,
  it,
  newtickets,
  browse,
  browse2,
  service,
  logo,
  menu_user,
  bglogin,
  pp_default,
  trash,
  approval,
  notification,
  modena_logo,
  chart_up,
  chart_down,
  login_bg,
  data_update,
  id_flag,
  data_update_dark,
  caret_up,
  chrome_tote,
  serious_warning,
  modena,
  datetime_input,
  mailbox,
  pending,
  unresolved,
  modena_letter,
  services,
  service_request,
  incident,
  issues,
  dashboard,
  requester_illus,
  microsoft,
  overdue,
};
