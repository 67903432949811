import React from "react";
import { NumberFormat } from "./NumberFormat";
import { ParseDecimal } from "./CurrencyInput/ParseDecimal";

const DashboardCard = ({
  card_title,
  stats,
  icon,
  explanation_text,
  icon_width,
  onClickFunc,
  cursor_type = "default",
}) => {
  return (
    <div
      className="card-body dashboard-card"
      onClick={onClickFunc}
      style={{ cursor: cursor_type }}
    >
      <div className="no-gutters align-items-center">
        <div className="col-auto d-flex justify-content-center">
          <img
            style={!icon_width ? { width: "150px" } : { width: icon_width }}
            src={icon}
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <h3
            className="bold black"
            style={{ display: "block", fontWeight: "600" }}
          >
            {card_title}
          </h3>
          <div
            style={{ fontSize: "3.5rem", color: "#3576C2", fontWeight: 500 }}
          >
            {NumberFormat(ParseDecimal(stats))}
          </div>
          <div style={{ marginTop: "10px" }}>{explanation_text}</div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
