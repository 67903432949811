import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2';
import { getGroup } from '../../Service/GroupService';
import {
    useNavigate,
    useParams
} from "react-router-dom";

const GroupMembers = ({id_group = null}) => {
    const navigate = useNavigate();
    const { group_id } = useParams();
    const [group_info, setGroupInfo] = useState({});
    useEffect(() => {
        let id = group_id? group_id: id_group? id_group:"";
        if(id){
            getGroup(id).then(res => {
                if(res.status == 200){
                    setGroupInfo(res.data);
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: "Member data retrieval failed. Please try again or contact support!"
                     }).then(() => navigate('/group-settings'))
                }
            }).catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: "Member data retrieval failed. Please try again or contact support!"
                 }).then(() => navigate('/group-settings'))
            })
        }
 
    },[])
    return(
       <>
            <div className="content-wrapper" style={{height:"120vh"}}>
                <div className="row" style={{marginBottom:"40px", paddingBottom:"10px"}}>
                    <div className="col-md-6">
                        <div style={{display:"flex"}}>
                            {!id_group &&<div>
                                <span class="material-icons" style={{fontSize:"30px", color: "black", cursor: "pointer"}} onClick={() => navigate('/group-settings')}>arrow_back</span>
                            </div>}
                            <div>
                                <h4 className='fw-500' style={{paddingLeft: 25, color:"black"}}>Group Members</h4>
                                <h6 style={{paddingLeft: 25, color:"black"}}>People involved in {group_info.group_name}</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{margin:"0 40px"}}>
                    <table className="table table-condensed" style={{borderRadius:0, marginBottom: 40, padding: "0.4rem"}}>
                            <thead>
                                <tr>
                                    <th className='b2b-th' style={{fontSize:"1.1em"}}>Supervisors</th>
                                </tr>
                            </thead>
                            <tbody>
                                {group_info.group_supervisors && group_info.group_supervisors.map((item, i) => 
                                        <tr>
                                            <td>
                                                {item.supervisor_name}
                                            </td>
                                        </tr> 
                                )}
                                
                                    
                            </tbody>
                    </table>
                    <table className="table table-condensed" style={{borderRadius:0, marginBottom: 0, padding: "0.4rem"}}>
                        <thead>
                            <tr>
                                <th className='b2b-th' style={{fontSize:"1.1em"}}>Agents</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(!group_info.group_agents || group_info.group_agents.length == 0) &&  
                                    <tr>
                                        <td>
                                            No agents assigned yet to this group
                                        </td>
                                    </tr> 
                            }
                            {group_info.group_agents && group_info.group_agents.length > 0 && group_info.group_agents.map((item, i) => 
                                    <tr>
                                        <td>
                                            {item.agent_name}
                                        </td>
                                    </tr> 
                            )}
                            
                                
                        </tbody>
                    </table>
                </div>
              
            </div>
        </>
    );
}

export default GroupMembers;