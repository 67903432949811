import React, {useState, useEffect} from 'react';
import { getAllByEmail, updateReadStatus } from '../../Service/NotificationService';
import { useSelector } from 'react-redux';
import Overlay from '../../Components/Overlay';
import Swal from 'sweetalert2';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
    
const Notifications = () => {
    let { userInfo } = useSelector(state => state.auth);
    const [state, setState] = useState({ processing : false });
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        getAllByEmail(userInfo.email, (response) => {
            if(response.status == 200){
                setNotifications(response.data);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: "Failed in getting notifications data!"
                 })
            }
        }).catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: "Failed in getting notifications data!"
             })
        });
    },[])

    
    const updateNotif = (e, item) => {
        const notif_id = item._id["$oid"];
        updateReadStatus(notif_id, {
            "is_read": true
        });
    }

    return(
        <div className="content-wrapper">
             <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                            <h2 className="title-breadcrum fw-500">Notifications</h2>
                            </div>
                        </div>
                    </div>
            </div>
            <section className="content">
                    <div className="container-fluid">
                        <div className="card shadow mb-4">
                            <div className="card-body">
                               
                                <Overlay display={state.processing} />
                                <div>
                                    {notifications?.map(item => {
                                        return (
                                            <>
                                                                                        <div className="card card-primary card-outline">
                                                <div className="card-body" style={{paddingTop:"0.3rem", paddingBottom:"0.3rem", }}>
                                                    <div style={{display:"flex", columnGap:"15px", alignItems:"center", justifyContent:"space-between"}}>
                                                        <div style={{display:"flex", columnGap:"15px", alignItems:"center"}}>
                                                            <div>&#9673; </div>
                                                            <NavLink onClick={(e) => updateNotif(e, item)} style={{fontSize:"0.8rem", fontWeight:600, color:"rgb(53, 118, 194)"}} target={"_blank"} to={item.link}>  {item.message}</NavLink>
                                                        
                                                        </div>
                                                        <div style={{float:"right", fontSize:"0.8rem"}}>{item.created_date ? moment(item.created_date).format('DD-MM-YYYY HH:mm') : 'Invalid date'}</div>
                                                    </div>
                                                </div>
                                            </div>
                                         </>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        </div>
    )
}

export default Notifications;