import axios from "axios";
import { handleResponse } from "./HelperService";

const getAllAgents = (params, onSuccess, onError) => {
  const response = axios.get("v1/agent", { params });
  return handleResponse(response, onSuccess, onError);
};

const getAllAgentsByGroup = (params, onSuccess, onError) => {
  const response = axios.get("v1/agent-by-group?filter=is_deleted:false", {
    params,
  });
  return handleResponse(response, onSuccess, onError);
};

const ratingSessionCheck = (ticket_no, session, onSuccess, onError) => {
  const response = axios.get(`v1/ticket-rating/${ticket_no}/${session}`);
  return handleResponse(response, onSuccess, onError);
};

const getAllSupervisors = (search, onSuccess, onError) => {
  const response = axios.get(`v1/agent-supervisors?search=${search}`);
  return handleResponse(response, onSuccess, onError);
};

const createAgent = (payload, onSuccess, onError) => {
  const response = axios.post("v1/agent", payload);
  return handleResponse(response, onSuccess, onError);
};

const getAgent = (id, onSuccess, onError) => {
  const response = axios.get(`v1/agent/${id}`);
  return handleResponse(response, onSuccess, onError);
};

const updateAgent = (id, payload, onSuccess, onError) => {
  const response = axios.put(`v1/agent/${id}`, payload);
  return handleResponse(response, onSuccess, onError);
};

const deleteAgent = (id, company_id, onSuccess, onError) => {
  const response = axios.delete(`v1/agent/${id}/${company_id}`);
  return handleResponse(response, onSuccess, onError);
};

export {
  getAllAgents,
  getAllAgentsByGroup,
  getAgent,
  updateAgent,
  createAgent,
  ratingSessionCheck,
  deleteAgent,
  getAllSupervisors,
};
