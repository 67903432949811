import {
  INCIDENT_FILTER,
  INCIDENT_SEARCH,
  REQUEST_FILTER,
  REQUEST_SEARCH,
} from "../Action/AppAction";

const init = {
  incident_search: "",
  incident_filter: "",
  request_search: "",
  request_filter: "",
};

const AppReducer = (state = init, action) => {
  switch (action.type) {
    case INCIDENT_FILTER:
      return { ...state, incident_filter: action.payload };
    case INCIDENT_SEARCH:
      return { ...state, incident_search: action.payload };
    case REQUEST_FILTER:
      return { ...state, request_filter: action.payload };
    case REQUEST_SEARCH:
      return { ...state, request_search: action.payload };
    default:
      return state;
  }
};

export default AppReducer;
