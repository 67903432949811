import axios from 'axios';
import { handleResponse } from './HelperService';

const updateReadStatus = (id, payload, onSuccess, onError) => {
    const response = axios.put(`v1/notification/${id}`, payload);
    return handleResponse(response, onSuccess, onError);
};

const getAllByEmail = (email, onSuccess, onError) => {
    const response = axios.get(`v1/notification/${email}`);
    return handleResponse(response, onSuccess, onError);
};

export {
    updateReadStatus,
    getAllByEmail
}