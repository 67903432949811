import { configureStore } from "@reduxjs/toolkit";

import thunk from "redux-thunk";
import AuthReducer from "../Reducer/AuthReducer";
import AppReducer from "../Reducer/AppReducer";

const store = configureStore({
  reducer: {
    auth: AuthReducer,
    app: AppReducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware().concat(thunk),
  ],
});

export default store;
