import React from "react";
import { useSelector } from "react-redux";
import AuthNavigation from "./Navigation/Auth/AuthNavigation";
import HomeNavigation from "./Navigation/Home/HomeNavigation";

const App = () => {
  const isLoggedIn = useSelector(({ auth: { isLoggedIn } }) => isLoggedIn);
  let { userInfo } = useSelector((state) => state.auth);
  return isLoggedIn === true ? <HomeNavigation /> : <AuthNavigation />;
  {
    /**Ok Test CICD */
  }
};

export default App;
